import React, { Component } from 'react';
import { Container, Row, Col, Card, CardTitle, CardBody, CardText } from 'shards-react';
import { Fade } from 'react-reveal';

const logoNosotros = require('../assets/Nosotros.png');
const logoMision = require('../assets/mision.png');
const logoVision = require('../assets/Vision.jpg');
export default class Nosotros extends Component {
  render() {
    return (
      <Container>
        <Row className id="nosotros">
          <Col>
            <Fade>
              <h2 className="m-0 ml-3 font-weight-bold">Nuestra Empresa</h2>
            </Fade>
          </Col>
        </Row>
        <Row>
          <Col>
            <Fade left>
              <Card className="mt-4 mx-auto w-100">
                <CardBody>
                  <Row>
                    <Col sm="4" style={{ minWidth: '183px' }}>
                      <img
                        className="mx-auto"
                        height="100%"
                        width="100%"
                        src={logoNosotros}
                        alt="Nosotros"
                      />
                    </Col>
                    <Col sm="8" className="pl-4 pr-5" style={{ minWidth: '300px' }}>
                      <Row className="mt-4">
                        <h4 className="font-weight-bold">Nosotros</h4>
                      </Row>
                      <Row className="mt-2 text-justify" style={{ fontWeight: 400, fontSize: 17 }}>
                        Somos un equipo de profesionales, dedicados a crear soluciones tecnológicas
                        para empresas y emprendedores, nuestro esfuerzo está dirigido a garantizar
                        el diseño y desarrollo de aplicaciones web y móvil a medida, de forma
                        eficiente y eficaz para nuestros clientes.
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Fade>
          </Col>
        </Row>

        <Row>
          <Col>
            <Fade right>
              <Card className="mt-4 mx-auto w-100">
                <CardBody>
                  <Row>
                    <Col sm="4" className="order-lg-last" style={{ minWidth: '183px' }}>
                      <img
                        className="mx-auto"
                        height="100%"
                        width="100%"
                        src={logoMision}
                        alt="Misión"
                      />
                    </Col>
                    <Col sm="8" className="pr-4 pl-5" style={{ minWidth: '300px' }}>
                      <Row className="mt-5">
                        <h4 className="font-weight-bold">Misión</h4>
                      </Row>
                      <Row className="mt-2 text-justify" style={{ fontWeight: 400, fontSize: 17 }}>
                        Acercar a nuestros clientes a su público objetivo con la mejor tecnología,
                        implementando soluciones adaptadas a sus necesidades, para incrementar su
                        competitividad y productividad.
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Fade>
          </Col>
        </Row>

        <Row>
          <Col>
            <Fade left>
              <Card className="mt-4 mx-auto w-100 ">
                <CardBody>
                  <Row>
                    <Col sm="4" style={{ minWidth: '183px' }}>
                      <img
                        className="mx-auto"
                        height="100%"
                        width="100%"
                        src={logoVision}
                        alt="Nosotros"
                      />
                    </Col>
                    <Col sm="8" className="pl-4 pr-5" style={{ minWidth: '300px' }}>
                      <Row className="mt-5">
                        <h4 className="font-weight-bold">Visión</h4>
                      </Row>
                      <Row className="mt-2 text-justify" style={{ fontWeight: 400, fontSize: 17 }}>
                        Acelerar el desarrollo de soluciones con impacto en las empresas y personas,
                        adaptándonos a los cambios del mercado y necesidades de nuestros clientes.
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Fade>
          </Col>
        </Row>
      </Container>
    );
  }
}
