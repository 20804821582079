import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, animateScroll as scroll } from 'react-scroll';

import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Collapse,
} from 'shards-react';

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);

    this.state = {
      dropdownOpen: false,
      collapseOpen: false,
      bgcolor: '',
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const bgcolor = window.scrollY < 100 ? 'transparent' : '#180533';
      this.setState({ bgcolor: bgcolor });
    });
  }

  toggleDropdown() {
    this.setState({
      ...this.state,
      ...{
        dropdownOpen: !this.state.dropdownOpen,
      },
    });
  }

  toggleNavbar() {
    this.setState({
      ...this.state,
      ...{
        collapseOpen: !this.state.collapseOpen,
      },
    });
  }

  render() {
    const { bgcolor } = this.state;
    return (
      <Navbar
        type="dark"
        expand="md"
        style={{ transition: 'background .6s', opacity: '0.9', backgroundColor: bgcolor }}
      >
        <NavbarBrand href="#" className="ml-3" style={{ fontSize: '22px', fontWeight:'bold'}}>
          FLiot
        </NavbarBrand>
        <NavbarToggler onClick={this.toggleNavbar} />

        <Collapse style={{}} open={this.state.collapseOpen} navbar>
          <Nav navbar style={{ fontSize: '20px', color: '#ffffff' }}>
            <NavItem className="ml-3 d-flex align-items-center">
              <Link
                style={{ cursor: 'pointer' }}
                activeClass="active"
                to="nosotros"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Nuestra Empresa
              </Link>
            </NavItem>
            <NavItem className="ml-3 d-flex align-items-center">
              <Link
                style={{ cursor: 'pointer' }}
                activeClass="active"
                to="servicios"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Servicios
              </Link>
            </NavItem>
            <NavItem className="ml-3 d-flex align-items-center">
              <Link
                style={{ cursor: 'pointer' }}
                activeClass="active"
                to="FormularioContacto"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Contacto
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
