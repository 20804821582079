import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Container } from 'shards-react';
import { Link, animateScroll as scroll } from 'react-scroll';
import { Fade } from 'react-reveal';

const facebook = require('../assets/facebook.svg');
const instagram = require('../assets/instagram.svg');
const twitter = require('../assets/twitter.svg');
const whatsapp = require('../assets/whatsapp.svg');
const mail = require('../assets/mail.svg');

export default class Footer extends Component {
  render() {
    return (
        <Card
          style={{
            backgroundColor: '#280657',
            borderRadius: '0px',
          }}
        >
          <CardBody className="pb-3" style={{ color: '#ffffff' }}>
            <Row className id="footer">
              <Col>
                <Container>
                  <Row className="justify-content-center">
                    <Col className="col-md-9 text-truncate">
                      <Link
                        style={{ cursor: 'pointer' }}
                        activeClass="active"
                        to="nosotros"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                      >
                        Nuestra Empresa
                      </Link>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="col-md-9">
                      <Link
                        style={{ cursor: 'pointer' }}
                        activeClass="active"
                        to="servicios"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                      >
                        Servicios
                      </Link>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="col-md-9">
                      <Link
                        style={{ cursor: 'pointer' }}
                        activeClass="active"
                        to="portafolio"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                      >
                        Portafolio
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </Col>

              <Col>
                <Row className="mt-2">
                  <Col className="d-flex align-items-center justify-content-center">
                    <img height="20" width="20" src={mail} className="mr-2" />
                    contacto.fliot@gmail.com
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4 justify-content-center">© FLiot 2020</Row>
          </CardBody>
        </Card>
    );
  }
}
