import React from 'react';
import { Container, Row, Col } from 'shards-react';
import { Fade } from 'react-reveal';

import Navbar from './components/NavBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'shards-ui/dist/css/shards.min.css';

import './App.css';
import Portafolio from './components/Portafolio';
import Presentacion from './components/Presentacion';
import ProgressBar from './components/ProgressBar';
import Servicios from './components/Servicios';
import FormularioContactoContainer from './components/FormularioContactoContainer';
import Nosotros from './components/Nosotros';
import Footer from './components/Footer';

function App() {
  return (
    <Container style={{ overflowY: 'scrollbars-none' }} fluid>
      <Row className="main-navbar fixed-top w-auto">
        <Col className="main-content p-0" sm="12" tag="main">
          <Navbar />
          <ProgressBar />
        </Col>
      </Row>
      <Row>
        <Col className="main-content p-0" sm="12" tag="main">
          <Presentacion />
        </Col>
      </Row>
      <Row className="mt-5 pb-5">
        <Col className="main-content p-0" sm="12" tag="main">
          <Nosotros />
        </Col>
      </Row>
      <Row className="mt-5 pb-5">
        <Col className="main-content p-0" sm="12" tag="main">
          <Servicios />
        </Col>
      </Row>
      <Fade bottom>
        <Row className="pt-5 mt-5" style={{ backgroundColor: '#280657' }}>
          <Col className="main-content p-0" sm="12" tag="main">
            <FormularioContactoContainer bc="#280657" />
            <Footer />
          </Col>
        </Row>
      </Fade>
    </Container>
  );
}

export default App;
