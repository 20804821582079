import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'shards-react';
import { Heading, Flex } from 'rebass';
import { CallToAction, Phone, MacWindow } from 'react-landing-page';
import { Zoom, Fade } from 'react-reveal';

export default class Portafolio extends Component {
  render() {
    return (
      <Container className="dr-example-container">
        <Row className id="portafolio">
          <Col>
            <Fade>
              <h2 className="m-0 ml-3 font-weight-bold">Portafolio</h2>
            </Fade>
          </Col>
        </Row>
        <Row>
          <Col>
            <Fade left>
              <Card
                className="mt-4"
                style={{
                  background:
                    "linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url('https://images.unsplash.com/photo-1577041904008-7483e24be04c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80')",
                  backgroundSize: 'cover',
                  borderRadius: '20px',
                }}
              >
                <CardBody>
                  <Flex
                    style={{ height: '400px', minWidth: '300px' }}
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <Flex alignItems="flex-start" width={[1 / 2, 1 / 2]} p={1}>
                      <Phone
                        src="https://via.placeholder.com/187x406"
                        style={{ transform: 'translate(0px, 0px)' }}
                      />
                      <Phone
                        src="https://via.placeholder.com/205x412"
                        color="white"
                        style={{ transform: 'translate(-20px, 15px)' }}
                      />
                    </Flex>

                    <Flex width={[1 / 2, 1 / 2]} alignItems="center" flexDirection="column" p={1}>
                      <Heading color="white">Mobile App Hero</Heading>

                      <Flex color="white" mt={3} flexWrap="wrap" justifyContent="center">
                        <CallToAction bg="black" mb={2}>
                           App store
                        </CallToAction>
                        <CallToAction bg="black" mb={2}>
                          Google Play
                        </CallToAction>
                      </Flex>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </Fade>
          </Col>
          <Col>
            <Fade right>
              <Card
                className="mt-4"
                style={{
                  background:
                    "linear-gradient(rgba(0,0,0,.0), rgba(0,0,0,.0)), url('https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80')",

                  backgroundSize: 'cover',
                }}
              >
                <CardBody>
                  <Flex
                    className="d-flex justify-content-center"
                    style={{ height: '400px', minWidth: '300px' }}
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <Flex alignItems="flex-start" p={1}>
                      <MacWindow
                        src="https://via.placeholder.com/640x480"
                        style={{ transform: 'translate(0px, 0px)' }}
                      />
                      <Phone
                        src="https://via.placeholder.com/205x412"
                        color="white"
                        style={{ transform: 'translate(-20px, 15px)' }}
                      />
                    </Flex>

                    <Flex color="white" mt={3} flexWrap="wrap" justifyContent="center">
                      <CallToAction style={{}} bg="black" mb={2}>
                        ApioTech
                      </CallToAction>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </Fade>
          </Col>
        </Row>
        <Row>
          <Col>
            <Fade bottom>
              <Card
                className="mt-4"
                style={{
                  background:
                    "linear-gradient(rgba(255,255,255,.0), rgba(255,255,255,.0)), url('https://images.unsplash.com/photo-1523115191856-c203e76215a5?ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80')",

                  backgroundSize: 'cover',
                }}
              >
                <CardBody>
                  <Flex
                    className="d-flex justify-content-center"
                    style={{ height: '400px', fontColor: 'white', minWidth: '300px' }}
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <Flex alignItems="flex-start" p={1}>
                      <MacWindow
                        src="https://via.placeholder.com/640x480"
                        style={{ transform: 'translate(0px, 0px)' }}
                      />
                      <Phone
                        src="https://via.placeholder.com/235x412"
                        color="white"
                        style={{ transform: 'translate(-20px, 15px)' }}
                      />
                    </Flex>

                    <Flex
                      style={{ color: '#FFFFFF', transform: 'translate(0px, 0px)' }}
                      mt={3}
                      flexWrap="wrap"
                      justifyContent="center"
                    >
                      <CallToAction bg="black" mb={2}>
                        Chile Sin Brechas
                      </CallToAction>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </Fade>
          </Col>
          <Col>
            <Fade bottom>
              <Card
                className="mt-4"
                style={{
                  background:
                    "linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url('https://images.unsplash.com/photo-1559310589-2673bfe16970?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80')",
                  backgroundSize: 'cover',
                }}
              >
                <CardBody>
                  <Flex
                    style={{ height: '400px', minWidth: '300px' }}
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <Flex alignItems="flex-start" width={[1 / 2, 1 / 2]} p={1}>
                      <Phone
                        src="https://via.placeholder.com/187x406"
                        style={{ transform: 'translate(0px, 0px)' }}
                      />
                      <Phone
                        src="https://via.placeholder.com/205x412"
                        color="white"
                        style={{ transform: 'translate(-20px, 15px)' }}
                      />
                    </Flex>

                    <Flex width={[1 / 2, 1 / 2]} alignItems="center" flexDirection="column" p={1}>
                      <Heading color="white">Mobile App Hero</Heading>

                      <Flex color="white" mt={3} flexWrap="wrap" justifyContent="center">
                        <CallToAction bg="black" mb={2}>
                           App store
                        </CallToAction>
                        <CallToAction bg="black" mb={2}>
                          Google Play
                        </CallToAction>
                      </Flex>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </Fade>
          </Col>
        </Row>
      </Container>
    );
  }
}
