import React, { Component } from 'react';
import { Container, Row, Col } from 'shards-react';
import { Flex } from 'rebass';
import { CallToAction, ScrollDownIndicator } from 'react-landing-page';
import { Link } from 'react-scroll';
import Typist from 'react-typist';
import './Typist.css';

// const logo = require('../assets/LogoFLiot.png');
// const fondo = 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1052&q=80'
const fondo = require('../assets/fondoweb01.png');
const ilustracion = require('../assets/ilustracionfliot06.png');

export default class Presentacion extends Component {
  render() {
    return (
      <Container
        className="p-0 m-0 mw-100 h-100"
        style={{
          background: `url(${fondo})`,
          backgroundPosition: 'center',
        }}
      >
        <Flex className="min-vh-100 mx-auto p-0" alignItems="center">
          <Row className="p-0 m-0 justify-content-center" sm="12">
            <Col className="order-md-2 order-sm-2" sm="6" style={{ minWidth: 320 }}>
              <img className="img-fluid" src={ilustracion} alt="ilustracion" style={{}} />
            </Col>
            <Col className="order-md-1 order-sm-2" sm="4" style={{ minWidth: 320 }}>
              <Flex className="h-100 mx-auto" alignItems="center">
                <Container>
                  <Row
                    className="d-flex justify-content-center font-weight-regular"
                    style={{
                      color: 'white',
                      fontSize: 40,
                      textAlign: 'center',
                    }}
                  >
                    <Typist
                      className="Typist"
                      avgTypingDelay={84}
                      cursor={{
                        show: true,
                        blink: true,
                        element: '|',
                      }}
                    >
                      <Link
                        style={{ cursor: 'pointer' }}
                        activeClass="active"
                        to="footer"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                      >
                        Llevamos tu idea a producción
                      </Link>
                    </Typist>
                  </Row>
                  <Row className="mt-4 d-flex justify-content-center">
                    <Flex color="white" flexWrap="wrap" justifyContent="center">
                      <CallToAction bg="black">
                        <Link
                          style={{ cursor: 'pointer' }}
                          activeClass="active"
                          to="footer"
                          spy={true}
                          smooth={true}
                          offset={-100}
                          duration={500}
                        >
                          Contáctanos
                        </Link>
                      </CallToAction>
                    </Flex>
                  </Row>
                </Container>
              </Flex>
            </Col>
          </Row>
          <Row className="mt-5 p-0 m-0 d-none d-sm-block" sm="12" style={{ minHeight: 200 }}>
            <ScrollDownIndicator style={{ color: 'white', fontSize: '30px' }} />
          </Row>
        </Flex>
      </Container>
    );
  }
}
