import React, { Component } from 'react';

export default class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = `${(scrollPx / winHeightPx) * 100}%`;

    // console.log(scrolled);

    this.setState({
      scrolled: scrolled,
    });
  };

  render() {
    /*const progressContainerStyle = {
      background: "#bbbff8",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
      height: "5px",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };
    */

    const progressContainerStyle = {
      height: '5px',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      zIndex: 99,
    };

    /*
    const progressBarStyle = {
      height: "5px",
      background: "#52A8FF",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      width: this.state.scrolled
    };
*/

    const progressBarStyle = {
      height: '5px',
      background: '#74b9ff',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
      width: this.state.scrolled,
    };

    return (
      <div className="progress-container" style={progressContainerStyle}>
        <div className="progress-bar" style={progressBarStyle} />
      </div>
    );
  }
}
