import React from 'react';
import {
  Container,
  Row,
  Col,
  InputGroup,
  FormInput,
  FormTextarea,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'shards-react';
import { Fade } from 'react-reveal';
import './Captcha.css';

const modalImg = require('../assets/contacto_mensaje.svg');

const styles = {
  null: {
    boxShadow:
      'inset 2px 2px 6px 0 rgba(0, 0, 0, 0.7), inset -6px -6px 12px 0 rgba(255, 255, 255, 1)',
    border: 0,
    backgroundColor: '#E3EDF7',
    color: '#23395b',
    fontWeight: 500,
  },
  true: {
    boxShadow:
      'inset 2px 2px 6px 0 rgba(0, 134, 4, 0.7), inset -6px -6px 12px 0 rgba(255, 255, 255, 1)',
    border: 0,
    backgroundColor: '#E3EDF7',
    color: '#008603',
    fontWeight: 500,
  },
  false: {
    boxShadow:
      'inset 2px 2px 6px 0 rgba(255, 0, 0, 0.7), inset -6px -6px 12px 0 rgba(255, 255, 255, 1)',
    border: 0,
    backgroundColor: '#E3EDF7',
    color: '#7B0000',
    fontWeight: 500,
  },
};

export default function FormularioContacto(props) {
  const {
    bc,
    formulario,
    syteKey,
    modal,
    verificarCaptcha,
    toggle,
    handleInputChange,
    handleClick,
  } = props;

  const {
    nombre,
    telefono,
    email,
    mensaje,
    nombreValido,
    telefonoValido,
    emailValido,
    mensajeValido,
  } = formulario;

  return (
    <Container style={{ backgroundColor: bc }}>
      <Modal open={modal} toggle={toggle} centered>
        <ModalHeader>Mensaje Enviado</ModalHeader>
        <ModalBody style={{ fontWeight: 400 }}>
          <img src={modalImg} alt="envío exitoso" />
          Nuestro equipo revisará su solicitud y se pondrá en contacto para profundizar sobre el
          proyecto. Una vez dispongamos de toda la información necesaria elaboraremos una propuesta
          comercial.
        </ModalBody>
      </Modal>
      <Row className id="FormularioContacto">
        <Col style={{ minWidth: 300 }}>
          <Fade>
            <h2 className="mb-4 ml-3 font-weight-bold" style={{ color: '#FFFFFF' }}>
              Contacto
            </h2>
            <h4 style={{ color: '#FFFFFF' }} className="mb-4">
              Ofrecemos una gran variedad de servicios. Déjanos tu consulta, proyecto o idea. Nos
              contactaremos contigo a la brevedad.
            </h4>
          </Fade>
        </Col>
        <Col style={{ minWidth: 300 }}>
          <Container>
            <Row>
              <InputGroup className="mb-2" seamless>
                <FormInput
                  placeholder="Nombre"
                  className="ml-1"
                  id="nombre"
                  name="nombre"
                  onChange={(ev) => {
                    handleInputChange(ev);
                  }}
                  value={nombre}
                  style={styles[nombreValido]}
                />
              </InputGroup>
            </Row>
            <Row className="mt-3">
              <Col style={{ padding: 0, paddingRight: 5 }}>
                <InputGroup className="mb-2" seamless>
                  <FormInput
                    placeholder="Teléfono"
                    className="ml-1"
                    id="telefono"
                    name="telefono"
                    value={telefono}
                    onChange={(ev) => {
                      handleInputChange(ev);
                    }}
                    style={styles[telefonoValido]}
                  />
                </InputGroup>
              </Col>
              <Col style={{ padding: 0, paddingLeft: 5 }}>
                <InputGroup className="mb-2" seamless>
                  <FormInput
                    placeholder="Email"
                    className="ml-1"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(ev) => {
                      handleInputChange(ev);
                    }}
                    style={styles[emailValido]}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-3" style={{ height: 100 }}>
              <FormTextarea
                placeholder="Consulta, proyecto o idea"
                id="mensaje"
                name="mensaje"
                value={mensaje}
                onChange={(ev) => {
                  handleInputChange(ev);
                }}
                style={styles[mensajeValido]}
              />
            </Row>
            <Row className="mt-2"></Row>
            <Row className="mt-3">
              <Button
                style={{ width: '100%' }}
                onClick={() => {
                  handleClick();
                }}
              >
                ENVIAR
              </Button>
            </Row>
            <Row className="mt-3" style={{ color: '#FFFFFF', fontSize: 12 }}>
              * This site is protected by reCAPTCHA and the Google &nbsp;
              <a href="https://policies.google.com/privacy" style={{ color: '#FFFFFF' }}>
                Privacy Policy
              </a>{' '}
              &nbsp; and&nbsp;
              <a href="https://policies.google.com/terms" style={{ color: '#FFFFFF' }}>
                Terms of Service
              </a>
              &nbsp; apply.
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
