import React, { Component } from 'react';
import {
  compose,
  minLength,
  maxLength,
  isString,
  matchesPattern,
  isPhone,
  isEmail,
} from 'deep-waters';

import { API_URL } from '../config';
import FormularioContacto from './FormularioContacto';

export default class FormularioContactoContainer extends Component {
  constructor(props) {
    super(props);
    const { bc } = props;

    this.state = {
      bc: bc,
      formulario: {
        nombre: '',
        nombreValido: null,
        telefono: '',
        telefonoValido: null,
        email: '',
        emailValido: null,
        mensaje: '',
        mensajeValido: null,
      },
      captchaToken: '',
      modal: false,
    };
  }

  componentDidMount() {}

  verificarCaptcha = (token) => {
    // Here you will get the final token!!!
    this.setState({
      captchaToken: token,
    });
  };

  validarFormulario = (formulario) => {
    const { nombre, telefono, email, mensaje } = formulario;

    const isNombre = compose(
      isString,
      matchesPattern(/^[^-\s][a-záéíóúäëïöü\d_\s]+$/i),
      minLength(10),
      maxLength(100),
    );
    formulario.nombreValido = isNombre(nombre);

    const isTelefono = compose(
      isString,
      isPhone,
      minLength(8),
      maxLength(12),
      matchesPattern(/^[+]{0,1}[0-9]+$/i),
    );
    formulario.telefonoValido = isTelefono(telefono);

    const isCorreo = compose(isString, isEmail, minLength(12), maxLength(100));
    formulario.emailValido = isCorreo(email);

    const isMensaje = compose(
      isString,
      minLength(10),
      maxLength(200),
      matchesPattern(/^[^-\s][a-záéíóúäëïöü\d_:.,\-\s]+$/i),
    );
    formulario.mensajeValido = isMensaje(mensaje);

    return formulario;
  };

  enviarFormulario = async ({ nombre, telefono, email, mensaje }, captchaToken) => {
    const response = await fetch(`${API_URL}/send`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        nombre: nombre,
        telefono: telefono,
        email: email,
        mensaje: mensaje,
        captchaToken: captchaToken,
      }),
    });
    const data = await response.json();
    return data;
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const { formulario } = this.state;
    formulario[name] = value;

    this.setState({
      formulario: formulario,
    });
  };

  handleClick = async () => {
    // * this.setState({ nombre: '', telefono: '', email: '', mensaje: '' });

    const { formulario, captchaToken } = this.state;
    let formularioValidado = this.validarFormulario(formulario);

    const { nombreValido, telefonoValido, emailValido, mensajeValido } = formularioValidado;

    let mostrarModal = false;
    if (nombreValido && telefonoValido && emailValido && mensajeValido && captchaToken !== '') {
      await this.enviarFormulario(formulario, captchaToken);
      mostrarModal = true;
      formularioValidado = {
        nombre: '',
        nombreValido: null,
        telefono: '',
        telefonoValido: null,
        email: '',
        emailValido: null,
        mensaje: '',
        mensajeValido: null,
      };
    }

    this.setState({
      formulario: formularioValidado,
      modal: mostrarModal,
    });
  };

  toggle = () => {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  };

  render() {
    const { bc, formulario, modal } = this.state;
    return (
      <FormularioContacto
        bc={bc}
        formulario={formulario}
        modal={modal}
        verificarCaptcha={this.verificarCaptcha}
        toggle={this.toggle}
        handleInputChange={this.handleInputChange}
        handleClick={this.handleClick}
      />
    );
  }
}
