import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardImg, CardTitle } from 'shards-react';
import { Fade } from 'react-reveal';
const logoDesarrolloWeb = require('../assets/desarrolloWebServicio.svg');
const logoDesarrolloMovil = require('../assets/desarrolloMovilServicio.svg');
const logoInfraestructura = require('../assets/infraestructuraServicio.svg');
const logoAppMapa = require('../assets/appMapasServicio.svg');
const logoConsultoriaServicio = require('../assets/consultoriaServicio.svg');
const logodisenoUiServicio = require('../assets/disenoUiServicio.svg');
const logo_desarrolloamedida = require('../assets/desarrollo_a_medida.svg');
const logo_geolocalizacion = require('../assets/geoloca.png');
const logo_disenoUx = require('../assets/ui-design.png');
const logo_consultoria = require('../assets/maintenance.png');
export default class Servicios extends Component {
  render() {
    return (
      <Container>
        <Row className id="servicios">
          <Col>
            <Fade>
              <h2 className="m-0 ml-3 font-weight-bold">Servicios</h2>
            </Fade>
          </Col>
        </Row>
        <Row>
          <Col>
            <Fade left>
              <Card
                style={{ minWidth: '350px', maxWidth: '350px', height: 400 }}
                className="mt-4 mx-auto"
              >
                <CardImg top src={logoDesarrolloWeb} height="150" width="150" className="mt-4" />
                <CardBody>
                  <Row>
                    <h4 className="font-weight-bold mx-auto">Desarrollo Web</h4>
                  </Row>
                  <Row className="text-center pl-1 pr-1" style={{ fontWeight: 350, fontSize: 17 }}>
                    Diseñamos páginas web que se ven increíbles. Priorizamos el rendimiento web y
                    los diseños profesionales personalizados para cada sector.
                  </Row>
                </CardBody>
              </Card>
            </Fade>
          </Col>
          <Col>
            <Fade bottom>
              <Card
                style={{ minWidth: '350px', maxWidth: '350px', height: 400 }}
                className="mt-4 mx-auto"
              >
                <CardImg top src={logoDesarrolloMovil} height="150" width="150" className="mt-4" />
                <CardBody className="text-center">
                  <Row>
                    <h4 className="font-weight-bold mx-auto">Desarrollo Movíl</h4>
                  </Row>
                  <Row className="text-center pl-1 pr-1" style={{ fontWeight: 350, fontSize: 17 }}>
                    Diseño y Desarrollo de aplicaciones móviles para iOS y Android, nos preocupados
                    de la mejor experiencia del usuario y rendimiento en cada aplicación que
                    desarrollamos.
                  </Row>
                </CardBody>
              </Card>
            </Fade>
          </Col>
          <Col>
            <Fade right>
              <Card
                style={{ minWidth: '350px', maxWidth: '350px', height: 400 }}
                className="mt-4 mx-auto"
              >
                <CardImg top src={logoInfraestructura} height="150" width="150" className="mt-4" />
                <CardBody className="text-center">
                  <Row>
                    <h4 className="font-weight-bold mx-auto">Infraestructura en la Nube</h4>
                  </Row>
                  <Row className="text-center pl-1 pr-1" style={{ fontWeight: 350, fontSize: 17 }}>
                    Realizamos todo el proceso de implementación en la nube para almacenamiento y
                    procesamiento de la información.
                  </Row>
                </CardBody>
              </Card>
            </Fade>
          </Col>
        </Row>
        <Row>
          <Col>
            <Fade left>
              <Card
                style={{ minWidth: '350px', maxWidth: '350px', height: 400 }}
                className="mt-4 mx-auto"
              >
                <CardImg top src={logoAppMapa} height="150" width="150" className="mt-4" />
                <CardBody className="text-center">
                  <Row>
                    <h4 className="font-weight-bold mx-auto">Aplicación de Mapas</h4>
                  </Row>
                  <Row className="text-center pl-1 pr-1" style={{ fontWeight: 350, fontSize: 17 }}>
                    Utilizamos datos de geolocalización para aumentar la usabilidad y omitir algunos
                    pasos entre el consumidor y el producto.
                  </Row>
                </CardBody>
              </Card>
            </Fade>
          </Col>
          <Col>
            <Fade bottom>
              <Card
                style={{ minWidth: '350px', maxWidth: '350px', height: 400 }}
                className="mt-4 mx-auto"
              >
                <CardImg
                  top
                  src={logoConsultoriaServicio}
                  height="150"
                  width="150"
                  className="mt-4"
                />
                <CardBody className="text-center">
                  <Row>
                    <h4 className="font-weight-bold mx-auto">Consultoría de TI</h4>
                  </Row>
                  <Row className="text-center pl-1 pr-1" style={{ fontWeight: 350, fontSize: 17 }}>
                    Desarrollamos consultorías para eliminar puntos débiles del flujo de trabajo,
                    implementar nueva tecnología y consolidar carteras de aplicaciones.
                  </Row>
                </CardBody>
              </Card>
            </Fade>
          </Col>
          <Col>
            <Fade right>
              <Card
                style={{ minWidth: '350px', maxWidth: '350px', height: 400 }}
                className="mt-4 mx-auto"
              >
                <CardImg top src={logodisenoUiServicio} height="150" width="150" className="mt-4" />
                <CardBody className="text-center">
                  <Row>
                    <h4 className="font-weight-bold mx-auto">Diseño UX/UI, diseño de prototipos</h4>
                  </Row>
                  <Row className="text-center pl-1 pr-1" style={{ fontWeight: 350, fontSize: 17 }}>
                    Ofrecemos en diseño de su idea de esta manera podrá validar gráficamente su
                    proyecto antes de comenzar a programarlo.
                  </Row>
                </CardBody>
              </Card>
            </Fade>
          </Col>
        </Row>
      </Container>
    );
  }
}
